import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Api from '@/resources/NajaSocialApi'

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    user: {
      id: null,
      token: null,
      short_token: null,
      structured_medical_report_available: null,
      role: '',
      name: null,
      username: null,
      cpf: null,
      email: null,
      naja_code: null,
      user_naja_code: null,
      termos_uso_aceitos: [],
      system_operations: [],
      voice_settings: {
        login: null,
        senha: null,
        servico: null,
      },
    },
    organization: {
      id: null,
      logo: null,
      name: null,
      subdomain: null,
      termos_portal: {
        id: null,
        texto: null,
        link_termos: null,
        nome: null,
        link_politica: null,
      },
    },
  }
}

const session = {
  state: getDefaultState(),
  mutations: {
    updateUser: (state, user) => {
      state.user = Object.assign(state.user, user)
    },

    updateTermosUsoAceitos: (state, termos_uso_aceitos) => {
      state.user.termos_uso_aceitos = Object.assign(
        state.user.termos_uso_aceitos,
        termos_uso_aceitos
      )
    },

    updateOrganization: (state, organization) => {
      state.organization = organization
    },

    clearUser: state => {
      state.user = Object.assign({}, getDefaultState().user)
    },
  },

  actions: {
    logout(context) {
      context.commit('clearUser')
      Api.toLogin()
    },

    async getUserInfo(context) {
      let result = await Api.me()
      context.commit('updateUser', result.data.user)
    },

    getOrganization(context) {
      return new Promise((resolve, reject) => {
        Api.getOrganizationInfo()
          .then(response => {
            context.commit('updateOrganization', response.data)
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
  },

  getters: {
    role: state => {
      return state.user.role
    },

    systemOperations: state => {
      return state.user.system_operations || []
    },

    token: state => {
      return state.user.token
    },

    isLogged: state => {
      return state.user.token !== undefined && state.user.token !== null
    },

    isOrganizationLoaded: state => {
      return state.organization.id != null
    },
  },
}

/* eslint-disable no-new */
const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['session'],
    }),
  ],
  modules: {
    session: session,
  },
})

export default store
