// import Api from '@/resources/NajaSocialApi'
import store from '@/store'
// import router from '@/router'

export default {
  install(Vue) {
    Vue.prototype.$hasPermission = permission => {
      return store.getters.systemOperations.includes(permission)
    }
  },
}
