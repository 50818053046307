import axios from 'axios'
import store from '@/store'
import $ from 'jquery'

function getSubdomain() {
  return window.location.host.split('.')[0]
}

function redirectToLogin() {
  const appName = 'Naja'
  // const redirectTo =
  //   process.env.ASSET_PATH == null
  //     ? `//${window.location.host}/auth`
  //     : `//${window.location.host}/${process.env.ASSET_PATH}/auth`

  const redirectTo = `//${window.location.host}/auth`

  const naja_login_host = `${getSubdomain()}.${
    process.env.VUE_APP_NAJA_LOGIN_HOST
  }`
  const url = `//${naja_login_host}/?app=${appName}&redirect=${redirectTo}&rules=collaborator&rules=admin`
  window.location.href = url
}

export const axiosInstance = axios.create({
  baseURL: `//${getSubdomain()}.${
    process.env.VUE_APP_NAJA_API_HOST
  }/naja-api/v1`,
  transformRequest: [
    function (data, headers) {
      headers['Naja-Api-Key'] = process.env.VUE_APP_NAJA_API_ACCESS_KEY
      if (store.getters.token != null) {
        headers['Authorization'] = store.getters.token
      }

      return JSON.stringify(data)
    },
  ],
  headers: { 'Content-Type': 'application/json' },
})

axiosInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
    }

    return Promise.reject(error)
  }
)

export const endpoints = {
  agenda: '/me/agenda',
  me: '/me',
  patients: '/patients',
  prontuarios: '/prontuarios',
  attachments: '/attachments',
  acceptTerms: '/accept-terms',
  termosUso: '/termos_uso',
  consultas: '/consultas',
  tiposConsulta: '/tipos_consulta',
  organizations: '/organizations',
  organizationInfo: '/organizations/info',
  branches: '/organizations/branches',
  settings: '/me/settings',
  updateSettings: '/me/update-settings',
  consultasPendentes: '/me/consultas-pendentes',
  taskLists: '/task_lists',
  medicalReport: '/medical_reports',
  pacs: '/medical_reports/pacs',
  exams: '/exams',
  userOrgaizations: '/user_organizations',
  users: '/users',
  taskSchedulers: '/task_schedulers',
  scheduleResources: '/schedule_resources',
  outboxes: '/outboxes',
  headerFooterTemplates: '/header_footer_templates',
  emailTemplates: '/email_templates',
  doctorSignatures: '/doctor_signatures',
  slugs: '/slugs',
  notificationSettings: '/notification_settings',
  medicalReportSettings: '/medical_report_settings',
  roles: '/roles',
  systemOperations: '/system_operations',
  roleSystemOperations: '/role_system_operations',
  versions: '/versions',
  memed: '/memed',
  njfiles: 'njfiles',
}

export default {
  axios: axiosInstance,
  baseURL: axiosInstance.defaults.baseURL,
  collaboratorBaseURL: axiosInstance.defaults.baseURL.replace(
    '/naja-api/v1',
    '/collaborator'
  ),
  oldApiBaseURL: axiosInstance.defaults.baseURL.replace('/naja-api/v1', '/api'),
  accessToken: process.env.VUE_APP_NAJA_API_ACCESS_KEY,
  agenda: params => axiosInstance.get(endpoints.agenda, { params: params }),
  toLogin: redirectToLogin,
  patients: params => axiosInstance.get(endpoints.patients, { params: params }),
  permissions: params =>
    axiosInstance.get(`${endpoints.me}/permissions`, { params: params }),
  checkPermissions: params =>
    axiosInstance.get(`${endpoints.me}/check-permissions`, { params: params }),
  organizationStatus: () =>
    axiosInstance.get(`${endpoints.organizations}/status`),
  organization: {
    doctors: () => axiosInstance.get(`${endpoints.organizations}/doctors`),
  },
  branches: () => axiosInstance.get(endpoints.branches),
  notificationSettings: {
    search: params =>
      axiosInstance.get(`${endpoints.notificationSettings}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.notificationSettings}/${id}`),
    events: () => axiosInstance.get(`${endpoints.notificationSettings}/events`),
    enable: id =>
      axiosInstance.get(`${endpoints.notificationSettings}/${id}/enable`),
    disable: id =>
      axiosInstance.get(`${endpoints.notificationSettings}/${id}/disable`),
    destroy: id =>
      axiosInstance.delete(`${endpoints.notificationSettings}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.notificationSettings}/${id}`
        : endpoints.notificationSettings
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  medicalReportSettings: {
    search: params =>
      axiosInstance.get(
        `${endpoints.medicalReportSettings}?${$.param(params)}`
      ),
    fetch: id => axiosInstance.get(`${endpoints.medicalReportSettings}/${id}`),
    lists: () => axiosInstance.get(`${endpoints.medicalReportSettings}/lists`),
    visibleFields: () =>
      axiosInstance.get(`${endpoints.medicalReportSettings}/visible_fields`),
    destroy: id =>
      axiosInstance.delete(`${endpoints.medicalReportSettings}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.medicalReportSettings}/${id}`
        : endpoints.medicalReportSettings
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  versions: {
    search: params =>
      axiosInstance.get(`${endpoints.versions}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.versions}/${id}`),
  },
  njfiles: {
    search: params =>
      axiosInstance.get(`${endpoints.njfiles}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.njfiles}/${id}`),
    destroy: id => axiosInstance.delete(`${endpoints.njfiles}/${id}`),
  },
  scheduleSearch: params =>
    axiosInstance.get(`schedules-search?${$.param(params)}`),
  inboxMessages: {
    search: params => axiosInstance.get(`inbox_messages?${$.param(params)}`),
  },
  notifications: {
    search: params => axiosInstance.get(`notifications?${$.param(params)}`),
  },
  doctorSearch: {
    fromRisWithSchedule: () =>
      axiosInstance.get('/doctors/from_ris_with_schedule'),
  },
  taskSchedulers: {
    search: params =>
      axiosInstance.get(`${endpoints.taskSchedulers}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.taskSchedulers}/${id}`),
    destroy: id => axiosInstance.delete(`${endpoints.taskSchedulers}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.taskSchedulers}/${id}`
        : endpoints.taskSchedulers
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  outboxes: {
    search: params =>
      axiosInstance.get(`${endpoints.outboxes}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.outboxes}/${id}`),
    types: () => axiosInstance.get(`${endpoints.outboxes}/types`),
    destroy: id => axiosInstance.delete(`${endpoints.outboxes}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id ? `${endpoints.outboxes}/${id}` : endpoints.outboxes
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  headerFooterTemplates: {
    search: params =>
      axiosInstance.get(
        `${endpoints.headerFooterTemplates}?${$.param(params)}`
      ),
    fetch: id => axiosInstance.get(`${endpoints.headerFooterTemplates}/${id}`),
    types: () => axiosInstance.get(`${endpoints.headerFooterTemplates}/types`),
    destroy: id =>
      axiosInstance.delete(`${endpoints.headerFooterTemplates}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.headerFooterTemplates}/${id}`
        : endpoints.headerFooterTemplates
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  slugs: {
    search: params =>
      axiosInstance.get(`${endpoints.slugs}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.slugs}/${id}`),
    types: () => axiosInstance.get(`${endpoints.slugs}/types`),
    destroy: id => axiosInstance.delete(`${endpoints.slugs}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id ? `${endpoints.slugs}/${id}` : endpoints.slugs
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  emailTemplates: {
    search: params =>
      axiosInstance.get(`${endpoints.emailTemplates}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.emailTemplates}/${id}`),
    types: () => axiosInstance.get(`${endpoints.emailTemplates}/types`),
    destroy: id => axiosInstance.delete(`${endpoints.emailTemplates}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.emailTemplates}/${id}`
        : endpoints.emailTemplates
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  doctorSignatures: {
    search: params =>
      axiosInstance.get(`${endpoints.doctorSignatures}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.doctorSignatures}/${id}`),
    types: () => axiosInstance.get(`${endpoints.doctorSignatures}/types`),
    destroy: id => axiosInstance.delete(`${endpoints.doctorSignatures}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.doctorSignatures}/${id}`
        : endpoints.doctorSignatures
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  roles: {
    search: params =>
      axiosInstance.get(`${endpoints.roles}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.roles}/${id}`),
    destroy: id => axiosInstance.delete(`${endpoints.roles}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id ? `${endpoints.roles}/${id}` : endpoints.roles
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
    updateRoleSystemOperations: (id, data) =>
      axiosInstance.put(
        `${endpoints.roles}/${id}/role_system_operations`,
        data
      ),
  },
  roleSystemOperations: {
    search: params =>
      axiosInstance.get(`${endpoints.roleSystemOperations}?${$.param(params)}`),
  },
  systemOperations: {
    search: () => axiosInstance.get(endpoints.systemOperations),
    // fetch: id => axiosInstance.get(`${endpoints.systemOperations}/${id}`),
    // destroy: id => axiosInstance.delete(`${endpoints.systemOperations}/${id}`),
    // save: (id, data) => {
    //   const method = id ? 'put' : 'post';
    //   const url = id ? `${endpoints.systemOperations}/${id}` : endpoints.systemOperations;
    //   const options = { method: method, url: url, data: data };
    //   return axiosInstance(options);
    // }
  },
  userOrgaizations: {
    search: params =>
      axiosInstance.get(`${endpoints.userOrgaizations}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.userOrgaizations}/${id}`),
    destroy: id => axiosInstance.delete(`${endpoints.userOrgaizations}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id
        ? `${endpoints.userOrgaizations}/${id}`
        : endpoints.userOrgaizations
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  users: {
    search: params =>
      axiosInstance.get(`${endpoints.users}?${$.param(params)}`),
    fetch: id => axiosInstance.get(`${endpoints.users}/${id}`),
    destroy: id => axiosInstance.delete(`${endpoints.users}/${id}`),
    save: (id, data) => {
      const method = id ? 'put' : 'post'
      const url = id ? `${endpoints.users}/${id}` : endpoints.users
      const options = { method: method, url: url, data: data }
      return axiosInstance(options)
    },
  },
  memed: {
    getUser: externalId =>
      axiosInstance.get(`${endpoints.memed}/users/${externalId}`),
    createDoctor: data =>
      axiosInstance.post(`${endpoints.memed}/create_doctor`, data),
    removeUser: externalId =>
      axiosInstance.delete(`${endpoints.memed}/users/${externalId}`),
  },
  taskLists: params =>
    axiosInstance.get(endpoints.taskLists, { params: params }),
  taskListExams: (id, params) =>
    axiosInstance.get(`${endpoints.taskLists}/${id}/exams`, { params: params }),
  patient: id => axiosInstance.get(`${endpoints.patients}/${id}`),
  prontuario: (id, params) =>
    axiosInstance.get(`${endpoints.prontuarios}/${id}`, { params: params }),
  me: () => axiosInstance.get(endpoints.me),
  settings: () => axiosInstance.get(endpoints.settings),
  updateSettings: data => axiosInstance.put(endpoints.updateSettings, data),
  consultasPendentes: () => axiosInstance.get(endpoints.consultasPendentes),
  getAttachment: id => axiosInstance.get(`${endpoints.attachments}/${id}`),
  getConsulta: id => axiosInstance.get(`${endpoints.consultas}/${id}`),
  getTiposConsulta: () => axiosInstance.get(endpoints.tiposConsulta),
  criarConsulta: data => axiosInstance.post(endpoints.consultas, data),
  atualizarConsulta: (id, data) =>
    axiosInstance.put(`${endpoints.consultas}/${id}`, data),
  termosUso: id => axiosInstance.get(`${endpoints.termosUso}/${id}`),
  acceptTerms: data => axiosInstance.post(endpoints.acceptTerms, data),
  getOrganizationInfo: () => axiosInstance.get(endpoints.organizationInfo),
  medicalReport: id => axiosInstance.get(`${endpoints.medicalReport}/${id}`),
  patientOldExams: id =>
    axiosInstance.get(`${endpoints.medicalReport}/${id}/patient_old_exams`),
  patientInfo: (id, params) =>
    axiosInstance.get(`${endpoints.medicalReport}/${id}/patient_info`, {
      params: params,
    }),
  patientPanel: params =>
    axiosInstance.get(`${endpoints.medicalReport}/patient`, {
      params: params,
    }),
  doctors: () => axiosInstance.get(`${endpoints.medicalReport}/doctors`),
  subgroups: () => axiosInstance.get('exams/subgroups'),
  order: params =>
    axiosInstance.get(`${endpoints.medicalReport}/order`, {
      params: params,
    }),
  attachments: params =>
    axiosInstance.get(endpoints.attachments, {
      params: params,
    }),
  examStatus: id =>
    axiosInstance.get(`${endpoints.medicalReport}/${id}/exam_status`),
  createMedicalReport: payload =>
    axiosInstance.post(endpoints.medicalReport, payload),
  updateMedicalReport: (id, payload) =>
    axiosInstance.put(`${endpoints.medicalReport}/${id}`, payload),
  reopenMedicalReport: id =>
    axiosInstance.put(`${endpoints.medicalReport}/${id}/reopen`),
  signMedicalReport: id =>
    axiosInstance.put(`${endpoints.medicalReport}/${id}/sign`),
  pacs: params =>
    axiosInstance.get(`${endpoints.pacs}?${params}`, { redirect: 'manual' }),
  examMedicalReport: id =>
    axiosInstance.get(`${endpoints.exams}/${id}/medical_report`),
  medicalReportTemplates: (id, params) =>
    axiosInstance.get(`${endpoints.medicalReport}/${id}/templates`, {
      params: params,
    }),
  structuredMedicalReport: params =>
    axiosInstance.get(`${endpoints.medicalReport}/structured`, {
      params: params,
    }),
  structuredReportState: id =>
    axiosInstance.get(
      `${endpoints.medicalReport}/${id}/structured_report_state`
    ),
  removeMedicalReport: id =>
    axiosInstance.delete(`${endpoints.medicalReport}/${id}`),
  copyMedicalReport: payload =>
    axiosInstance.post(`${endpoints.medicalReport}/copy`, payload),
  saveDictationMedicalReport: payload =>
    axiosInstance.post(`${endpoints.medicalReport}/save_dictation`, payload),
}
