import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import VTextField from 'vuetify/lib/components/VTextField'
import pt from 'vuetify/lib/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  options: {
    customProperties: true,
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        primary: '#F58221',
        secondary: '#787878',
        bold: '#384048',
      },
    },
  },
})
