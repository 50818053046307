import moment from 'moment'

export default {
  required: [v => !!v || 'Obrigatório'],
  email: required => {
    const rules = [
      v =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'E-mail inválido',
    ]

    if (required) {
      rules.push(v => !!v || 'Obrigatório')
    }

    return rules
  },
  cpf: required => {
    const rules = [
      v => !v || /(\d{3}).(\d{3}).(\d{3})-(\d{2})/.test(v) || 'CPF inválido',
    ]

    if (required) {
      rules.push(v => !!v || 'Obrigatório')
    }

    return rules
  },
  phone: required => {
    const rules = [
      v => !v || /\(\d{2}\)\s\d{4}\s\d{4,5}/.test(v) || 'Número inválido',
    ]

    if (required) {
      rules.push(v => !!v || 'Obrigatório')
    }

    return rules
  },
  // greaterThanZero: required => {
  //   const rules = [v => v > 0 || 'O valor deve ser maior que 0']
  //   if (required) {
  //     rules.push(v => !!v || 'Obrigatório')
  //   }
  // },

  greaterThanZero: v => {
    const numValue = parseFloat(v)
    if (!isNaN(numValue)) {
      return numValue > 0 || 'O valor deve ser maior que 0'
    }

    return true
  },
  number: v => !isNaN(v) || 'Informe um valor numérico',
  int: v => /^-?\d+$/.test(v) || 'Informe um numérico inteiro',
  require: v => !!v || 'Obrigatório',
  date: required => {
    const rules = [
      v => !v || moment(v, 'DD/MM/YYYY', true).isValid() || 'Data inválido',
    ]

    if (required) {
      rules.push(v => !!v || 'Obrigatório')
    }

    return rules
  },

  max: (limit, required) => {
    const rules = [
      v => !v || v.length <= limit || `Limite máximo de ${limit} caracteres`,
    ]

    if (required) {
      rules.push(v => !!v || 'Obrigatório')
    }

    return rules
  },
}
